/* MusicLibraryContainer.module.css */
.container {
  padding-top:10px;
  position: relative;
  margin: 150px 40px 90px 40px;
  max-height: calc(100vh);  
  overflow: none;  

}

.expand{
  margin: 200px 40px 90px 40px; 
}


.favoritesTitle {
  letter-spacing: 2px;
  font-size: 18px;
  color: white;
  top: 90px;
  z-index: 800;
  font-weight: bold;
  position: fixed;
  padding: 0px 20px 0px 40px;
  box-sizing:border-box;
}

.gradientMaskTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to bottom, var(--main-colors-black-1), rgba(255, 255, 255, 0));
  z-index: 2;
}

.gradientMaskBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to top, var(--main-colors-black-1), rgba(255, 255, 255, 0));
  z-index: 2;
}

.noTracksMessage {
  width: 100%;

  color: #9e9e9e;
  font-size: 1.2rem;
  position: relative;
  height: calc(100vh - 244px);
  z-index: 10;
  font-family: 'Juice';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  position: fixed;
  top: 50%;  /* Position it halfway down the viewport */
  left: 50%;  /* Center it horizontally */
  transform: translate(-50%, -50%);  /* Offset the element's own width and height to truly center it */
  z-index: 1000; /* Ensure it's above other content */
  color: rgba(var(--main-colors-white-rgb), 0.7);
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;
}

 