/* ProgressBar.module.css */
.progressBarContainer {
    height: 2px; /* Adjust height as needed */
    background-color: #635f5e; /* Custom background color */
    width: 100%; /* Full width */
    border-radius: 2px; /* Optional: for rounded corners */
}

.progressBar {
    height: 100%;
    background-color: rgba(255, 0, 0, 0.9); /* Custom color for the progress bar */
    width: 0%; /* Initial width */
    border-radius: 2px; /* Optional: for rounded corners */
    transition: width 0.5s ease; /* Smooth transition for width change */
}
