/* SoundWavePlaybarContainer.module.css */
.waveformContainer {
    position: relative;
    overflow-x: hidden;
    align-items: center;
    display: flex;
}

.audioRectangle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    z-index: 2;
    padding: 0px;
}

.minimapsContainer {
    height: 40px;
    pointer-events: auto;
}

.minimapsContainerHidden {
    display: none;
  }

.originalTrack{
    height: '0px';
    width: '100%';
    position: 'absolute';
    background-color: 'var(--main-colors-black-2)';
}