.container {
  top: 90px;
  position: fixed;
  z-index: 800;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid rgba(var(--main-colors-white-rgb), 0.2);
  border-bottom: 1px solid rgba(var(--main-colors-white-rgb), 0.2);
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 35px; /* Adjust padding as necessary */
  /* border-top: 1px solid rgba(var(--main-colors-white-rgb), 0.2); */
  box-sizing: border-box;
  gap: 10px; /* Add consistent gap between all elements */
}

.searchField {
  max-width: 400px; /* Fixed width for search field */
  margin-left: 10px; /* Ensure consistent gap from filters */
}

.selectBox, .sliderBox {
  flex: 1; /* Ensure equal width for sliders and select boxes */
  min-width: 150px; /* Set a reasonable minimum width */
}

.filters {
  display: flex;
  gap: 10px; 
}

.filters > * {
  flex: 1 1 0px; /* Allows the elements to grow but not shrink beyond their minimum content size */
  max-width: 200px; /* Maximum width each element can grow */
}

.tagsContainer {
  width: 100%;
  padding: 0px 35px 0px 42px;
  box-sizing: border-box;
  background-color: var(--main-colors-black-1);
  /* border-bottom: 1px solid rgba(var(--main-colors-white-rgb), 0.2); */
 
}


.adminActions {
  margin-left: auto; /* Aligns the admin actions to the right */
}

@media (max-width: 1024px) {
  .tagsContainer, .searchField, .sliderBox {
    display: none;
  }
}
 