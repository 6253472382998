.container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 2;
}

.fullscreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'transparent';
  z-index: 998;
  pointer-events: none;
  
}

.playBarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 84px;
    background-color: var(--main-colors-black-2);
    z-index: 1;
}

.playBarElements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 70px;
    margin-right: 0px;
    height: 100%;
    width: calc(100% - 70px);
    pointer-events: auto;
}

.fullscreenVideoContainer {
    top: 0;
    left: 0;
    z-index: 997;
    overflow: hidden;
}

.videoContainer {
  width: 32%;  
  height: 36%;
}

.videoContainer, .fullscreenVideoContainer {
  position: fixed;
  bottom: 84px;
  right: 0;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Push icons to the bottom */

}

.videoContainer video, .fullscreenVideoContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controlIconsContainer {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  display: flex;
  gap:20px; /* Space between the icons */
}

.playIcon, .fullscreenIcon, .resetIcon {
  color: white;
  cursor: pointer;
  pointer-events: auto;
}

.selectTrackMessage {
    margin: 0 auto;
    width: 450px;
    height: 60px;
    border-radius: 8px;
    background-color: var(--main-colors-black-3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(var(--main-colors-white-rgb), 0.4);
    font-size: 12px;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
}


 .toggleReferenceTrackContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  max-width: 160px;
}

@media screen and (max-width: 764px) {
  .toggleReferenceTrackContainer{
      display: none !important;
  }
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    max-width: 120px;
}

.songDetailsContainer{
   width:185px;
}


.fullScreenSongDetailsContainer {
  position: absolute;
  left: 0;
  width: 60%; /* Adjusts to content */
  padding: 10px; /* Optional: add padding */

}


.rawIcon {
  position: absolute;
  right:0;    
  bottom: 0;  
  color:white;
  margin: 0px 90px 10px 0px;
  cursor:pointer;
  pointer-events: auto;
}

@media screen and (max-width: 1024px) {
    .songDetailsContainer, .timeValues{
        display: none !important;
    }
}

.timeValues {
  display: flex;
 height:100%
}
 

@media screen and (max-width: 764px) {
  .favoritesButtonContainer{
      display: none !important;
  }
}

.outOfRangeContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Ensure it takes full width */
  height: 100%; /* Ensure it takes full height */
  background-color: rgba(var(--main-colors-black-4-rgb), 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 2; /* Ensure it appears above the video */
}

.outOfRangeContainerText {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  padding: 0px;
  box-sizing: border-box;  
  font-size: 16px;
  letter-spacing: 1px;
color:white;
  text-align: center; /* Center text */
}

.outOfRangeContainerText h2 {
  font-size: 20px;
  letter-spacing: 0px;
}

.opacity {
  opacity: 0.3;
}