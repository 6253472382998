 
/* Define your custom theme for Tippy.js */
.tippy-box[data-theme~='customTippyTheme'] {
    background-color: rgba(var(--main-colors-white-rgb),0.9);
    color: rgb(0,0,0);
    font-weight:bold; 
  }
  
  .tippy-box[data-theme~='customTippyTheme'] .tippy-arrow {
    color: rgba(var(--main-colors-white-rgb),0.9);
   top:14px;
  }

  .tippy-box[data-theme~='customTippyThemeWarning'] {
    background-color: #e30505;
    color: rgb(255, 255, 255);
    font-weight:bold;
  }
  
  .tippy-box[data-theme~='customTippyThemeWarning'] .tippy-arrow {
    color: #b50c0c;
    left: 50% !important;  
    transform: translateX(-50%) !important; 
  }
  