/* SongDetails.module.css */

.container {
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .songTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 72px;
    height: 70px;
    position: relative;
    width: auto;
  }
  
  .textContainer {
    text-align: left;
    white-space: pre-wrap;
    position: absolute;
  }

  .icon {
    font-size:20px;
    color: rgba(238, 238, 238, 0.8);
    margin-left: 0cqb;  
  }

  .titleSong {
    color: rgba(238, 238, 238, 1);
    font-weight: 600;
    font-size: 16px;
    display: inline-flex; 
    align-items: center;
    width: 239px;
    height: 27px;
    left: 22px;
    top: 19px;
  }
  
  .artistName {
    color: rgba(158, 158, 158, 1);
    font-weight: 400;
    font-size: 12px;
    left: 22px;
    top: 43px;
  }
