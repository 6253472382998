.trackDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align content to the left */
  background-color: var(--main-colors-black-1);
  color: white;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 250px);
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 10px;
  padding-left: 20px;
  /* Add padding to align content neatly */
}

.backgroundImageContainer {
  position: relative;
  width: 100%;
  height: 420px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
}

.title {
  font-size: 48px;
  margin-bottom: 10px;
  text-align: left;
  color: #ffffff;
}

.artist {
  font-size: 24px;
  /* Adjust this value to make the title bigger */
  margin-bottom: 20px;
  text-align: left;
  color: var(--main-colors-cyan-light-1) !important;
}

.buttons {
  display: flex;
  gap: 10px;
}

.details {
  width: 100%;

  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid rgba(var(--main-colors-white-rgb), 0.2);
  padding-left: 20px;
}

.albumContainer {
  display: flex;
  align-items: center;
  /* Align items vertically centered */
  margin-top: 50px;
  margin-bottom: 30px;
}

.albumImage {
  width: 75px;
  /* Small cover image size */
  height: 75px;
  /* Ensure it's square */
  border-radius: 4px;
  /* Optional: Rounded corners */
  margin-right: 15px;
  /* Space between image and text */
}

.albumTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.albumTitle {
  font-size: 18px;
  font-weight: bold;
  color: rgba(var(--main-colors-white-rgb), 0.6);
  margin-bottom: 5px;
  text-align: left;
}

.albumName {
  font-size: 22px;
  text-align: left;
  color: #ffffff;
  /* Ensures readability */
}





.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  margin: 5px !important;
  color: white !important;
  font-size: 16px !important;
  background: rgba(255, 255, 255, 0.18) !important;
}

.tag:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
  cursor: pointer;
}


.tagDisabled:hover {
  background: rgba(255, 255, 255, 0.18) !important;
  cursor: default;
}

.additionalDetails {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
}

.additionalDetailItem {
  margin-right: 60px;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-left: 20px;
}


.editableTitleInput {
  font-size: 1.5em; /* Adjust this to match the h1 font size */
  font-weight: bold; /* Match the font weight of the h1 */
  border: 1px solid #ccc;
  padding: 10px;
  width: 400px;
  box-sizing: border-box; /* Ensure the width includes padding and border */
  margin: 0 0 10px; /* Add some space below the input */
}