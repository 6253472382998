/* IconButton.module.css */
.iconButton {
    color: var(--main-colors-white)  !important;
    margin: 7px;
    border-radius: 50%;
    width: 50px; /* Increase button size */
    height: 50px; /* Increase button size */
}

.iconButtonHover:hover {
    background-color: var(--main-colors-black-3);
}

.icon {
    font-size: 24px;  
}

.tooltipText {
    font-size: 16px;  
}
