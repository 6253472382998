
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
  font-family: 'JuiiceLight';
  src: local('JuiiceLight'), url(/assets/fonts/JUICE_Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Juice';
  src: local('Juice'), url(/assets/fonts/JUICE_Regular.ttf) format('opentype');
}


body::-webkit-scrollbar {
  width: 0 !important;  /* for Chrome, Safari, and newer Opera */
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none;  
  scrollbar-width: none; 

}   

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row-padding {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.minimap-hidden {
  visibility: hidden;
  height: 0;
  overflow: hidden; /* Ensure no internal content spills out */
  transition: height 0.3s ease-out; /* Optional: for a smooth transition */
}
 

.icon-gray {
 color:rgba(var( --main-colors-white-rgb), 0.5);
}