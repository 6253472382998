.videoInfo {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(var(--main-colors-white-rgb), 0.8);
    margin-right: 10px;
    margin-top: 5px;
    line-height: 0.8;
    padding: 1px 10px;
    background-color: rgba(222, 31, 31, 0.8);
}

.smallFont {
    font-size: 8px;
    font-weight: bold;
}

.largeFont {
    font-size: 10px;
    font-weight: bold;
}

.smallTime {
    font-size: 10px;
    text-align: center;
}

.largeTime {
    font-size: 12px;
    text-align: center;
}
