.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;  
    margin-top: 180px;
    height: 350px;
    color: rgba(255, 255, 255, 0.77);  
}

.pageContainer > *:not(:last-child) {
    margin-bottom: -10px; 
}

.headerTitle, .subTitle, .errorCodeTitle {
    text-align: center;
    color: inherit;  
}

.headerTitle {
    letter-spacing: 2px;
    font-family: 'Juice', sans-serif;  
    font-size: 100px;
}

.subTitle {
    margin-top: -80px; 
    font-size: 20px;
}

.errorCodeTitle {
    font-size: 14px;
    margin-top: -90px;  
}
