/*ButtonGroup.module.css */

.container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 20px;
    margin:20px 0px;
  }
  

  @media (max-width: 991px) {
    .container {
      margin-top: 40px;
    }
  
    .nextButton {
      white-space: initial;
      padding: 0 20px;
    }
  }
  