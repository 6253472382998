/* UploadDialog.module.css */

.darken {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.7);
  z-index: 20000;
}

.modalOverlay {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(1, 1, 1, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20001;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  /* max-width: 90%; */
  max-height: 90%;
  padding: 30px 140px 3px 140px;
  box-sizing: border-box;

  overflow: auto; /* Allows scrolling if content exceeds size */
width: 900px; /* Set a fixed width */
 
  /* border:1px solid rgba(var(--main-colors-med-grey-rgb), 0.5); */
}

.closeButton {
  position: absolute;
  top: 0px;
  right: 0px;

}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;   
  height: 100%; 
  position: relative;
  padding:40px 0px 60px 0px;
}

.modalContent {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.titleEntering,
.subtitleEntering {
  opacity: 1;
  transform: translateY(25px);
  transition: opacity 500ms, transform 500ms;
}

.hide {
  opacity: 0;
  transform: translateY(65px);
  transition: opacity 500ms, transform 500ms;
}

.headerTitle,
.headerSubtitle {
  transition: opacity 500ms, transform 500ms;
}


.headerTitle {
  text-align: center;
  letter-spacing: 2px;
  font-family: 'Juice';
  font-size: 42px;
  color: white;
  font-weight: bold;
}

.headerSubtitle {
  text-align: center;
  color: #9e9e9e;
  letter-spacing: 0.4px;
  font-family: 'Juice';
  margin-top: 12px;
  font-size: 13px;
  width: 500px;
  line-height: 30px;
}

.uploadProgressContainer {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 175px;
  position: relative;
}

.fileName,
.fileInfo {
  color: white;
  margin-bottom: 10px;
  font-size: 12px;
}

.progressBar {
  width: 100%;
  background-color: #ccc;
  height: 2px;
  border-radius: 2.5px;
  margin-bottom: 0px;
}

.progress {
  background-color: #29d2e7;
  height: 100%;
  border-radius: 2.5px;
}

.closeButton {
  cursor: pointer;
  font-size: 24px;
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
}

.uploadAreaContainer {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  background-color: #1a1a1a;
  display: flex;
  width: 400px;
  height: 200px;
  flex-direction: column;
  padding: 20px 60px;
  margin: 24px 0 44px;
}

.uploadAreaContainer:hover {
  background-color: var(--main-colors-black-3);
  cursor: pointer;
}

.uploadIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
}

.uploadActionsContainer {
  align-self: center;
  display: flex;
  margin-top: 12px;
  gap: 4px;
}

.actionText {
  color: #29d2e7;
  letter-spacing: 0.32px;
  white-space: nowrap;
  flex-grow: 1;
  justify-content: center;
  font: 600 16px/100% Inter, sans-serif;
  cursor: pointer;
}

.dragDropText {
  color: rgba(255, 255, 255, 0.87);
  letter-spacing: 0.32px;
  flex-grow: 1;
  white-space: nowrap;
  font: 600 16px/100% Inter, sans-serif;
}

.fileTypesInfo {
  color: #9e9e9e;
  text-align: center;
  letter-spacing: 0.26px;
  margin-top: 12px;
  white-space: nowrap;
  font: 400 13px/123% Inter, sans-serif;
}

.switchWrapper {
  border-radius: 4px;
  /* background-color: #1a1a1a; */
  align-self: center;
  display: flex;
  margin-top: 50px;
  max-width: 90%;
  align-items: center;
  justify-content: space-between;
  padding: 15px 41px;
}

@media (max-width: 991px) {
  .uploadAreaContainer {
    padding: 0 20px;
  }

  .uploadContainer.isUploading {
    padding: 0 20px;
    height: 250px;
  }

  .uploadContent {
    margin: 40px 0;
  }

  .actionText,
  .dragDropText,
  .fileTypesInfo {
    white-space: initial;
  }

}