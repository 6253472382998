/* VideoPlayer.module.css */
.thumbnailVideo {
    object-fit: contain;
    display: block;
    width: 178px;
    height: 84px;
}

/* VideoPlayer.module.css */
.progressBarContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
