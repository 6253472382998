:root {
    /* Hex colors */
    --main-colors-white: #FFF;
    --main-colors-black-1: #000;
    --main-colors-black-2: #1A1A1A;
    --main-colors-black-3: #242424;
    --main-colors-black-4: #323232;
    --main-colors-black-5: #313131;
    --main-colors-cyan-light-1: #00E5FF;
    --main-colors-cyan-light-2: #00BCD4;
    --main-colors-light-grey: #9E9E9E;
    --main-colors-med-grey:#635f5e;
    --main-colors-yellow:#E6C427;

    /* RGB colors for opacity */
    --main-colors-white-rgb: 255, 255, 255;
    --main-colors-black-1-rgb: 0, 0, 0;
    --main-colors-black-2-rgb: 26, 26, 26;
    --main-colors-black-3-rgb: 36, 36, 36;
    --main-colors-black-4-rgb: 50, 50, 50;
    --main-colors-cyan-light-1-rgb: 0, 229, 255;
    --main-colors-cyan-light-2-rgb: 0, 188, 212;
    --main-colors-cyan-dark-1-rgb: 0,31,31;
    --main-colors-light-grey-rgb: 158, 158, 158;
    --main-colors-med-grey-rgb: 99,95,94;
    --main-colors-yellow-rgb: 230, 196, 39;
 
}
