/* VideoTimelineDragger.module.css */
.videoRectangle {
    border: 2px solid #9E9E9E;
    box-sizing: border-box;
    position: absolute;
    color: #ccc;
    font-size: 9px;
    padding: 3px 0px 0px 0px;
    text-align: right;
    font-family: Helvetica, sans-serif;
  
    height: 50px;
    z-index: 1002;

}

.dragHandle {
    cursor: grab;
    position: absolute;
    top: 2px;
    left: 1px;
    background-color: rgba(0,0,0,0);
    padding: 1px;
    color: white;
    font-size: 7px;
    text-align: center;
}

