/* SongList.module.css */
.songListWrapper {
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 250px);
  z-index: 1;
  color: #ffffff;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.songListWrapper::-webkit-scrollbar {
  display: none;
}