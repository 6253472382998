/* Upload.module.css */

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 80px;
  margin-bottom: 180px;
}

.titleEntering,
.subtitleEntering {
  opacity: 1;
  transform: translateY(25px);
  transition: opacity 500ms, transform 500ms;
}

.titleExiting,
.subtitleExiting {
  opacity: 0;
  transform: translateY(65px);
  transition: opacity 500ms, transform 500ms;
}

.headerTitle,
.headerSubtitle {
  transition: opacity 500ms, transform 500ms;
}


.headerTitle {
  text-align: center;
  letter-spacing: 2px;
  font-family: 'Juice';
  font-size: 42px;
  color: white;
  font-weight: bold;
}

.headerSubtitle {
  text-align: center;
  color: #eee8e8;
  letter-spacing: 0.4px;
  font-family: 'Juice';
  margin-top: 12px;
  font-size: 16px;
  max-width: 800px;
  line-height: 30px;
}

.uploadingContent {
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  height: 75px; /* Set a fixed height */
  position: relative;
}

.fileName,
.fileInfo {
  color: white;
  margin-bottom: 10px;
  font-size: 12px;
}

.progressBar {
  width: 100%;
  background-color: #ccc;
  height: 2px;
  border-radius: 2.5px;
  margin-bottom: 0px;
}

.progress {
  background-color: #29d2e7;
  height: 100%;
  border-radius: 2.5px;
}

.closeButton {
  cursor: pointer;
  font-size: 24px;
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
}

.uploadContainer {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.12);
  background-color: #1a1a1a;
  display: flex;
  width: 400px;
  flex-direction: column;
  padding: 10px 60px;
  margin-top: 40px;
}

.uploadContainer:hover {
  background-color:var(--main-colors-black-3);
  cursor:pointer;
}

.uploadAreaContainer{
  display: flex;
  width: 305px;
  max-width: 100%;
  flex-direction: column;
  margin: 64px 0 44px;
}

.uploadIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
}

.uploadActionsContainer {
  align-self: center;
  display: flex;
  margin-top: 12px;
  gap: 4px;
}

.actionText {
  color: #29d2e7;
  letter-spacing: 0.32px;
  white-space: nowrap;
  flex-grow: 1;
  justify-content: center;
  font: 600 16px/100% Inter, sans-serif;
  cursor: pointer;
}

.dragDropText {
  color: rgba(255, 255, 255, 0.87);
  letter-spacing: 0.32px;
  flex-grow: 1;
  white-space: nowrap;
  font: 600 16px/100% Inter, sans-serif;
}

.fileTypesInfo {
  color: #9e9e9e;
  text-align: center;
  letter-spacing: 0.26px;
  margin-top: 12px;
  white-space: nowrap;
  font: 400 13px/123% Inter, sans-serif;
}

.layoutContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

.thumbnail {
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.thumbnail-left {
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.thumbnail-right {
  animation: slideInFromRight 0.5s ease-out forwards;
}


@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}


@media (max-width: 991px) {
  .uploadContainer {
    padding: 0 20px;
  }

  .uploadContainer.isUploading {
    padding: 0 20px;
    height: 250px;
  }

  .uploadContent {
    margin: 40px 0;
    
  }

  .actionText,
  .dragDropText,
  .fileTypesInfo {
    white-space: initial;
  }

}

.customList {
  list-style-type: none;  
  padding-left: 25px;  
  font-size:12px;
}


.songListWrapper {
  width: 70%;
  margin: 40px auto 40px auto;
}


.loadingSpinner {
  display: flex;  /* Enable flexbox */
  justify-content: center;  /* Center horizontally */
  align-items: center;  /* Center vertically */
  height: 100%;  /* Take full height of the container */
  color: rgba(var(--main-colors-white-rgb), 0.7);
}