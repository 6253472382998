.wrapper {
  cursor: pointer;
  border-radius: 4px;
  height: auto;
  display: flex;
  width: 100%;
  padding: 0px 30px 0px 30px;
  background-color: transparent;
  align-items: center;
  justify-content: space-between;
}

.soundWaveContainer {
  display: flex;
  align-items: center;
  width: 150px;
  margin:0 10px;
}

.songDetails {
  flex-shrink: 0;
  min-width: 200px;
}

.timeValues {
  display: flex;
 height:100%
}
 

@media (max-width: 1024px) {
  .timeValues, .songDetails {
    display: none;
  }
}

@media (max-width: 1280px) {
  .tagsContainer {
    display: none;
  }
}